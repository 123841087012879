<template>
    <div class="match-container">
        <div class="banner-size">
            <div
                class="banner-limit banner-info"
                style="position: relative"
            >
            </div>
        </div>
        <div class="home-top1">
            <div class="border-top"></div>
            <div class="home-top-content banner-limit">
                <!-- 搜索 -->
                <div class="home-topL">
                    <div
                        class="flex-column flex-center"
                        style="width: 100%"
                    >
                        <!-- <div class="home-topL-title prohibit-text"></div> -->
                        <div class="home-topL-search flex-column">
                            <div class="input-search-bar">
                                <div class="input-search-pre">企业名称</div>
                                <div class="s-line"></div>
                                <FormSelectAutoComplete
                                    v-model="searchInfo.data.tyshxydm"
                                    size=""
                                    :lazyMethod="searchComponay"
                                >
                                    <el-option
                                        v-for="(item,index) in searchInfo.companyData"
                                        :key="index"
                                        :value="item.shxydm"
                                        :label="item.label"
                                    >
                                        <template>
                                            <span v-html="item.name"></span>
                                        </template>
                                    </el-option>
                                </FormSelectAutoComplete>
                                <!-- <el-autocomplete
                                    class="input-search"
                                    v-model="policyWord"
                                    :fetch-suggestions="getAllEnter"
                                    placeholder="请输入企业关键字搜索"
                                    @select="selectEnter"
                                >
                                    <template slot-scope="{ item }">
                                        <div
                                            class="name"
                                            v-html="item.name"
                                        ></div>
                                    </template>
                                </el-autocomplete> -->

                                <el-button
                                    class="input-search-btn"
                                    @click="getPolicyList"
                                >匹配</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="match-container"
            v-if="policyList.length !== 0"
        >
            <div class="main-con">
                <div class="one-title">
                    <span>
                        共查找到
                        <span class="st-txt">{{ policyList.length }}</span>
                        条记录，
                    </span>
                    <span>
                        预计本企业最高可申请资助：
                        5星：<span>{{cpu_maxAmount(5)}}</span>万元，
                        4星：<span>{{cpu_maxAmount(4)}}</span>万元，
                        3星：<span>{{cpu_maxAmount(3)}}</span>万元，
                        总计：<span class="st-txt">{{cpu_maxAmount()}}万元</span>
                    </span>
                </div>
            </div>
            <div v-if="policyList.length !== 0">
                <div
                    v-for="item in policyList"
                    :key="item.id"
                    class="each-list main-con"
                >
                    <div class="each-title">
                        <span class="title-name">{{item.name}}</span>
                        <el-tag size="mini">
                            {{getDictLabel("sq_policy_zjlx", item.itempCode)}}类
                        </el-tag>
                        <el-rate
                            v-model="item.ratenum"
                            disabled
                        ></el-rate>
                        <p>最高补助金额：{{item.zzxe?item.zzxe+'万元':'无'}}</p>
                    </div>
                    <div class="mid-info">
                        <div class="each-info">
                            <span class="infoItem-label">申报期限：</span>
                            <div class="infoItem-value">
                                {{cpu_formatDate(item.startTime)}}
                                ~
                                <span class="col-danger">{{cpu_formatDate(item.endtime)}}</span>
                                <div v-if="cpu_limitTime(item.endtime)">
                                    (剩余
                                    <span class="col-danger">{{cpu_limitTime(item.endtime)}}</span>
                                    天)
                                </div>
                            </div>
                        </div>
                        <div class="each-info">
                            <span class="infoItem-label">资金限额：</span>
                            <div class="infoItem-value">{{item.capital?item.capital+'万元':'无'}}</div>
                        </div>
                        <div class="each-info">
                            <span class="infoItem-label">地区：</span>
                            <div class="infoItem-value">深圳</div>
                        </div>
                        <div class="each-info">
                            <span class="infoItem-label">受理部门：</span>
                            <div class="infoItem-value">{{item.deptName}}</div>
                        </div>
                    </div>
                    <div class="bot-info">
                        <div class="bot-txt">
                            申报时间：<span class="right-txt">未启动</span>
                        </div>
                        <div class="bot-txt sec-txt">
                            支持力度：
                            <div class="right-txt max-txt">{{ item.exerciseContent }}</div>
                        </div>
                    </div>
                    <div class="policyItem-footer">
                        <el-button
                            type="primary"
                            round
                            size="mini"
                            @click="clickCell(item)"
                        >办事指南</el-button>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-else
            class="match-empty"
        >(请在上方输入要查询的企业名称)</div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { FormSelectAutoComplete } from '@/components/form/index.js'
import { formatDate } from '@/utils/dateHandler.js'

export default {
    name: "policyMatch",
    components: {
        FormSelectAutoComplete,
    },
    data() {
        return {
            policyWord: "",
            policyList: [],
            searchInfo: {
                data: {
                    tyshxydm: '', // 企业统一社会信用代码
                },
                companyData: [], // 企业列表
            },
        };
    },
    computed: {
        ...mapGetters({
            userInfo: 'getUserInfo',
            getDictLabel: 'dictionary/getDictLabel',
        }),
        cpu_formatDate() {
            return function (date) {
                return formatDate(date, 'date') || '无'
            }
        },
        cpu_limitTime() {
            return function (endTime) {
                if (!endTime) return ''

                let end = new Date(endTime).getTime()
                let now = new Date().getTime()

                if (end < now) return ''

                return Math.floor((end - now) / (24 * 60 * 60 * 1000))
            }
        },
        cpu_maxAmount() {
            return function (rate) {
                let list = this.policyList
                if (rate) {
                    list = this.policyList.filter(cv => cv.ratenum == rate)
                }
                return list.reduce((total, cv) => {
                    return total + Number(cv.zzxe || 0)
                }, 0)
            }
        },
    },
    created() {
        this.$store.dispatch('dictionary/commonSetDic', {
            types: ['sq_policy_zjlx']
        })
    },
    methods: {
        /**
         * @description: 联想企业
         * @param {String} keyword 搜索关键字
         * @param {Function} cb 回调函数
         * @return {*}
         */
        searchComponay(keyword, cb) {
            let url = '/dev-api/elasticMgr/getEnterpriseDatas'
            let params = {
                pageNum: 1,
                pageSize: 50,
                keyWords: keyword,
            }
            this.searchInfo.companyData = []
            this.$httpApi.get(url, params).then(res => {
                // console.log('queryCompany:', res)
                if (res.code == 200) {
                    this.searchInfo.companyData = (res.data || []).map(cv => {
                        return {
                            ...cv,
                            label: cv.name.replace(/<[^<>]+>/g, ''),
                        }
                    })
                    cb(res.data || [])
                } else {
                    cb([])
                }
            }).catch(err => {
                console.log('queryCompany error:', err)
                cb([])
            })

        },
        getAllEnter(queryString, cb) {
            let params = {
                keyWords: queryString,
                pageSize: 50,
                pageNum: 0,
            };
            let comList = [];
            this.$httpApi
                .get(`/dev-api/elasticMgr/getEnterpriseDatas`, params)
                .then((res) => {
                    console.log("搜索结果>>>", res);
                    if (res.code == 200 && res.data) {
                        comList = res.data;
                        cb(comList);
                    } else {
                        cb([{ name: "暂无数据" }]);
                    }
                });
        },
        selectEnter(item) {
            this.policyWord = item.name.replace(/<[^>]+>/g, "");
        },
        clickCell(item) {
            // console.log("选择的是:", item);
            this.$store.commit("updateCurrentGuideId", item.id)
            this.$router.push({
                path: "/guideSuqiu",
                query: {
                    id: item.id,
                    name: item.name,
                },
            });
        },
        getPolicyList() {
            let params = {
                itemtype: 2,
                pageSize: 20,
                pageNum: 0,
            };
            this.policyList = [];
            if (!this.searchInfo.data.tyshxydm) return
            // if (!this.policyWord) return
            this.$httpApi
                .get(`/dev-api/basicinfo/baseitem/zjl/xmsbList`, params)
                .then((res) => {
                    console.log("政策列表>>>", res);
                    if (res.code == 200 && res.rows) {
                        this.policyList = res.rows
                        /**
                         * 20210509
                         * 增加 政策匹配度/最高补助金额
                         * 目前剃光的接口设计为获取单个政策的匹配度和补助金额
                         * 列表会发N个请求，暂时遗留这个性能问题
                         */
                        for (let i = 0; i < this.policyList.length; i++) {
                            this.getPolicyRate(this.policyList[i])
                        }
                    }
                });
        },
        /**
         * @description: 查询政策匹配度和最高补助金额
         * @param {Object} policy 政策
         */
        getPolicyRate(policy) {
            let url = '/dev-api/sqzdManager/data/find/sq_policy_matchrate_query'
            let params = {
                tyshxydm: this.searchInfo.data.tyshxydm,
                zcid: policy.id,
            }
            this.$httpApi.post(url, params).then(res => {
                if (res.data && !!res.data.length) {
                    // 可能有多种匹配标准，取最高的展示
                    let ratenum = res.data[0].ratenum || 0
                    let zzxe = Number(res.data[0].zzxe || 0)
                    for (let i = 1; i < res.data.length; i++) {
                        if (res.data[i].ratenum && ratenum <= res.data[i].ratenum) {
                            if (res.data[i].zzxe && zzxe <= Number(res.data[i].zzxe)) {
                                ratenum = res.data[i].ratenum || 0
                                zzxe = Number(res.data[i].zzxe)
                            }
                        }
                    }
                    this.$set(policy, 'ratenum', (ratenum * 5) || 4)
                    this.$set(policy, 'zzxe', zzxe || 500)
                    this.$forceUpdate()
                }
            })
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/com/theme.scss";

.col-danger {
    color: $color-danger;
}
.match-container {
    width: 100vw;
    overflow-x: hidden;
    min-height: calc(100vh - 270px);
    display: flex;
    flex-direction: column;
    align-items: center;
    .mid-con {
        margin-top: 8%;
        width: 40%;
        position: relative;
        .mid-input {
            width: 100%;
        }
        .show-select {
            position: absolute;
            left: 97px;
            top: 50px;
            width: calc(100% - 97px);
            height: 26vh;
            overflow-y: auto;
            background: #fff;
            box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.12);
            border-radius: 6px;
            .no-data {
                text-align: center;
                line-height: 26vh;
                color: #999;
                font-size: 14px;
            }
            .each-enter {
                padding: 0 10px;
                line-height: 40px;
                font-size: 16px;
                margin-left: 12px;
                cursor: pointer;
            }
        }
        .bot-pipei {
            position: absolute;
            right: -100px;
            top: 0;
        }
    }
    .home-top1 {
        width: 100%;
        position: relative;
        .home-top-content {
            // background-color: red;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);

            .home-topL {
                width: 670px;
                .home-topL-title {
                    width: 480px;
                    // height: 115px;
                    // background: url("../../assets/images/search_title.png")
                    //     center / 100% 100% no-repeat;
                }
                .home-topL-search {
                    width: 100%;
                    height: 100%;
                    color: $color-fff;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    .s-line {
                        background: #dddddd;
                        width: 1px;
                        height: 50%;
                    }
                    .input-search-bar {
                        // margin: 0px 0 20px 0;
                        width: 80%;
                        height: 50px;
                        // border: 5px solid rgba(#000, 0.1);
                        border-width: 4px 5px;
                        border-radius: 4px;
                        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
                        // margin-bottom: 20px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        background: #ffffff;

                        /* 输入框头部 */
                        .input-search-pre {
                            //   height: 50px !important;
                            width: 120px !important;
                            color: #404040;
                            font-size: 14px;
                            text-align: center !important;
                        }
                        .form-select-auto-complete {
                            flex: 1;
                        }
                        /* 输入框内容 */
                        .input-search {
                            //   height: 50px !important;
                            flex: 1;
                            .el-input__inner {
                                border: none !important;
                                border-radius: 0 !important;
                                background-color: #ffffff !important;
                            }
                            input::-webkit-input-placeholder {
                                color: #999999;
                            }
                        }
                        /* 输入框按钮 */
                        .input-search-btn {
                            height: 100%;
                            width: 100px;
                            border-radius: 0 !important;
                            border-top-right-radius: 4px !important;
                            border-bottom-right-radius: 4px !important;
                            text-align: center;
                            // background: $color-danger;
                            background: #24d286;
                            border: 1px solid #24d286;
                            font-size: $font-size18;
                            color: $color-fff;
                            cursor: pointer;
                            .el-button {
                                font-weight: bold !important;
                            }
                        }
                    }
                    .input-tips {
                        .input-tips-item {
                            font-size: $font-size12;
                            height: $font-size12;
                            line-height: $font-size12;
                            padding: 0 10px;
                            color: $color-fff;
                            max-width: 200px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }
    .match-container {
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #f4f6fc;
        padding: 50px 0;
        .main-con {
            width: 80vw;
            border-radius: 10px;
            background: #fff;
            .one-title {
                line-height: 60px;
                margin-left: 20px;
                font-size: 14px;
                .st-txt {
                    color: $col-theme;
                }
            }
        }
        .each-list {
            margin-top: 20px;
            padding: 20px 30px;

            .each-title {
                display: -webkit-flex;
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .title-name {
                    font-size: 16px;
                    font-weight: bold;
                }
                ::v-deep .el-tag,
                ::v-deep .el-rate {
                    margin-left: 20px;
                }
            }
            .mid-info {
                display: -webkit-flex;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                gap: 5px 5px;
                padding-bottom: 10px;
                border-bottom: 1px solid #eee;
                .each-info {
                    display: -webkit-flex;
                    display: flex;
                    width: 300px;

                    .infoItem-label {
                        color: $color-primary;
                    }
                    .infoItem-value {
                        flex: 1;
                    }
                }
            }
            .bot-info {
                margin-top: 10px;
                padding-bottom: 10px;
                display: flex;
                align-items: center;
                .bot-txt {
                    color: #8590a6;
                    font-weight: bold;
                    font-size: 14px;
                    .right-txt {
                        font-weight: 400;
                    }
                    .max-txt {
                        max-width: 50vw;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
                .sec-txt {
                    margin-left: 5vw;
                    display: flex;
                }
            }
            .policyItem-footer {
                text-align: right;
            }
        }
    }
}
.banner-size {
    background: url("../../assets/images/icon_banner_declare.png");
}
.match-empty {
    margin: 40px auto;
    font-size: 20px;
}
</style>
